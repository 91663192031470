<template>
  <div class="sample-database-detail dashboard-card">
    <div class="sample-database-detail-header">
      <BackButton :clickMethod="back" class="mb-3"/>
    </div>
    <div class="sample-database-detail-body cs-scroll-style">
      <b-row>
        <b-col cols="12" md="3" lg="3">
          <div class="pop-desc">
            <div class="title-header">
              <h5>{{ 'Population' }}</h5>
            </div>
            <div class="desc">
              {{popData.title}}
            </div>
          </div>

          <div class="pop-desc">
            <div class="title-header">
              <h5>{{ 'Date' }}</h5>
            </div>
            <div class="desc">
              {{popData.date}}
            </div>
          </div>

          <div class="pop-desc">
            <div class="title-header">
              <h5>{{ 'Location' }}</h5>
            </div>
            <div class="desc">
              {{popData.location}}
            </div>
          </div>

          <div class="pop-desc" v-if="researchArray">
            <div class="title-header">
              <h5>{{ 'Research' }}</h5>
            </div>
            <div class="desc">
              <div v-html="researchArray"></div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="5" lg="5">
          <div class="title-header">
            <h5>{{ 'Genetic Profile' }}</h5>
          </div>
          <div class="p-1">
            <h5 v-if="!farmerHgResultList">Unavailable</h5>
            <template v-else >
              <div v-for="itemAncestry in farmerHgResultList.outPuts" :key="itemAncestry.resultsTable" class="farmer-hgs mb-1">
                <span class="label">{{ itemAncestry.resultsTable.replaceAll('*', ' ') }} :</span>
                <span class="percent"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              </div>
            </template>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <div class="title-header">
            <h5>{{ 'Genetically Closest Populations' }}</h5>
          </div>
          <div class="distance-container">
            <table class="table table-bordered distance-table">
              <thead>
                  <tr>
                    <th scope="col">Distance</th>
                    <th scope="col">Modern population</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="distanceItem in distanceList" :key="distanceItem.title">
                    <td><span class="td-size" :style="{ color: distanceItem.color }"> {{ distanceItem.distance }}</span></td>
                    <td>{{distanceItem.title.replaceAll('*', ' ')}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
      <hr class="ancient-hr"/>
      <b-row class="mt-3">
        <b-col cols="12" md="3" lg="3">
          <div class="pop-desc">
            <div class="title-header">
              <h5>{{ 'Sample(s)' }}</h5>
            </div>
            <div class="desc">
              <ul>
                <li v-for="item in samples" :key="item">
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="3" lg="3">
          <div class="pop-desc">
            <div class="title-header">
              <h5>{{ 'Y-DNA' }}</h5>
            </div>
            <div class="desc">
              <ul>
                <li v-for="item in yDna" :key="item">
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="3" lg="3">
          <div class="pop-desc">
            <div class="title-header">
              <h5>{{ 'mtDNA' }}</h5>
            </div>
            <div class="desc">
              <ul>
                <li v-for="item in mtDna" :key="item">
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';

export default {
  components: {
    BackButton,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$store.state.ancientDatabase.sampleDetail = null
    },
  },
  computed: {
    popData() {
      return this.$store.state.ancientDatabase.sampleDetail;
    },
    researchArray() {
      const research = this.popData.research;
      if (research) {
        const pattern = /<\/?[a-z][\s\S]*>/i;
        if (pattern.test(research)) {
          return research;
        }

        const researchArray = research ? research.split('\n') : [];
        return researchArray
                        .map((res) => `<a href="${res}" class="researchlink" target="_blank">${res}</a>`)
                        .join(", ");
      }
      return null;
    },
    samples() {
      return this.popData.samples ? this.popData.samples.split(',') : [];
    },
    mtDna() {
      return this.popData.mtDna ? this.popData.mtDna.split(',') : [];
    },
    yDna() {
      return this.popData.yDna ? this.popData.yDna.split(',') : [];
    },

    distanceList() {
      return this.$store.state.sampleDatabase.calculatedDistance;
    },
    farmerHgResultList() {
      return this.$store.state.sampleDatabase.calculatedFarmerHgResult;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
</style>
