<template>
  <div class="mb-4">
    <div class="sample-database-detail dashboard-card">
      <div class="sample-database-detail-body cs-scroll-style">
        <b-row>
          <b-col cols="12" class="mb-4">
            <div class="title-header">
              <h5>{{ 'Genetic Profile' }}</h5>
            </div>
            <div class="p-1">
              <h5 v-if="!farmerHgResultList">Unavailable</h5>
              <template v-else>
                <div v-for="itemAncestry in farmerHgResultList.outPuts" :key="itemAncestry.resultsTable" class="farmer-hgs mb-1">
                  <span class="label">{{ itemAncestry.resultsTable.replaceAll('*', ' ') }} :</span>
                  <span class="percent"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
                </div>
              </template>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="title-header mb-1">
              <h5>{{ 'Genetically Closest Populations' }}</h5>
            </div>
            <div class="distance-container">
              <!-- <b-table
                :fields="fields"
                :items="distanceList"
                responsive="sm"
                head-variant="light"
                small
                bordered
                outlined
              >

                <template #cell(title)="data">
                  <span> 
                    {{ data.item.title.replaceAll('*', ' ') }}
                  </span>
                </template>
                <template #cell(distance)="data">
                  <span> {{ data.item.distance }}</span>
                </template>
              </b-table> -->
              <table class="table distance-table">
                <thead>
                    <tr>
                      <th scope="col">Distance</th>
                      <th scope="col">Modern population</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="distanceItem in distanceList" :key="distanceItem.title">
                      <td><span class="td-size" :style="{ color: distanceItem.color }"> {{ distanceItem.distance }}</span></td>
                      <td>{{distanceItem.title.replaceAll('*', ' ')}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <!-- <hr class="ancient-hr"/> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      fields: [
        {
          // A virtual column with custom formatter
          key: 'distance',
          label: 'Distance',
        },
        {
          // A regular column with custom formatter
          key: 'title',
          label: 'Modern Population',
        },
      ],
    }
  },
  methods: {
    back() {
      this.$store.state.ancientIndividualDatabase.sampleDetail = null
    },
  },
  computed: {
    popData() {
      return this.$store.state.ancientIndividualDatabase.sampleDetail;
    },
    samples() {
      return this.popData.samples ? this.popData.samples.split(',') : [];
    },
    mtDna() {
      return this.popData.mtDna ? this.popData.mtDna.split(',') : [];
    },
    yDna() {
      return this.popData.yDna ? this.popData.yDna.split(',') : [];
    },

    distanceList() {
      return this.$store.state.sampleDatabase.calculatedDistance;
    },
    farmerHgResultList() {
      return this.$store.state.sampleDatabase.calculatedFarmerHgResult;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.sample-database-detail {
  background-color: var(--white-100);
}
</style>
